export default {
  dishes: {
    pizza: [
      {
        name: "Margherita",
        prices: [7.0, 13.0],
        id: 28,
        ingredients: ["Tomaten", "Käse"],
      },
      {
        name: "Salami",
        prices: [7.0, 13.0],
        id: 2,
        ingredients: ["Tomaten", "Käse", "Salami"],
      },
      {
        name: "Prosciutto",
        prices: [7.0, 13.0],
        id: 3,
        ingredients: ["Tomaten", "Käse", "Schinken"],
      },
      {
        name: "Funghi",
        prices: [7.0, 13.0],
        id: 4,
        ingredients: ["Tomaten", "Käse", "Champignons"],
      },
      {
        name: "Salamino Picante",
        prices: [7.0, 13.0],
        id: 5,
        ingredients: ["Tomaten", "Käse", "Peperoniwurst"],
      },
      {
        name: "Napoli",
        prices: [7.5, 13.5],
        id: 6,
        ingredients: ["Tomaten", "Käse", "Salami", "Champignons"],
      },
      {
        name: "Treviso",
        prices: [7.5, 13.5],
        id: 7,
        ingredients: ["Tomaten", "Käse", "Schinken", "Champignons"],
      },
      {
        name: "Lucia",
        prices: [7.5, 13.5],
        id: 8,
        ingredients: ["Tomaten", "Käse", "Salami", "Schinken"],
      },
      {
        name: "Romana",
        prices: [7.5, 14.0],
        id: 9,
        ingredients: ["Tomaten", "Käse", "Salami", "Schinken", "Champignons"],
      },
      {
        name: "Mista",
        prices: [7.5, 14.0],
        id: 10,
        ingredients: ["Tomaten", "Käse", "Salami", "Schinken", "Peperoniwurst"],
      },
      {
        name: "Michelangelo",
        prices: [8.0, 15.0],
        id: 11,
        ingredients: [
          "Tomaten",
          "Käse",
          "Schinken",
          "Peperoniwurst",
          "Champignons",
          "Paprika",
        ],
      },
      {
        name: "Vier Käse",
        prices: [8.0, 15.0],
        id: 12,
        ingredients: [
          "Tomaten",
          "Mozzarella",
          "Gorgonzola",
          "Parmesan",
          "Gruviera",
        ],
      },
      {
        name: "Speciale",
        prices: [8.0, 15.0],
        id: 13,
        ingredients: ["Tomaten", "Käse", "Salami", "Peperoniwurst", "Zwiebeln"],
      },
      {
        name: "Vier Jahreszeiten",
        prices: [8.0, 15.0],
        id: 14,
        ingredients: [
          "Tomaten",
          "Käse",
          "Schinken",
          "Artischocken",
          "Paprika",
          "Champignons",
        ],
      },
      {
        name: "Fuoco",
        prices: [8.5, 15.5],
        id: 15,
        ingredients: [
          "Tomaten",
          "Käse",
          "Salami",
          "Peperoniwurst",
          "Paprikaschoten",
          "Spiegelei",
          "Tomatenscheiben",
          "(scharf)",
        ],
      },
      {
        name: "Mare",
        prices: [9.0, 17.0],
        id: 16,
        ingredients: ["Tomaten", "Käse", "Meeresfrüchte"],
      },
      {
        name: "Hawaii",
        prices: [7.5, 14.0],
        id: 17,
        ingredients: ["Tomaten", "Käse", "Schinken", "Ananas"],
      },
      {
        name: "Suprema",
        prices: [8.5, 15.5],
        id: 18,
        ingredients: [
          "Tomaten",
          "Käse",
          "Salami",
          "Schinken",
          "Peperoniwurst",
          "Zwiebeln",
          "Champignons",
          "Peperonischoten",
        ],
      },
      {
        name: "Salvatore",
        prices: [8.0, 15.0],
        id: 19,
        ingredients: [
          "Tomaten",
          "Käse",
          "Salami",
          "Peperoniwurst",
          "Zwiebeln",
          "Champignons",
        ],
      },
      {
        name: "Vegetaria",
        prices: [8.5, 15.5],
        id: 20,
        ingredients: [
          "Tomaten",
          "Käse",
          "Champignons",
          "Broccoli",
          "Artischocken",
          "Paprika",
        ],
      },
      {
        name: "Tonno",
        prices: [8.0, 15.0],
        id: 21,
        ingredients: [
          "Tomaten",
          "Käse",
          "Thunfisch",
          "Zwiebeln",
          "Champignons",
        ],
      },
      {
        name: "Superiore",
        prices: [8.5, 15.5],
        id: 22,
        ingredients: [
          "Tomaten",
          "Käse",
          "Salami",
          "Schinken",
          "Champignons",
          "Zwiebeln",
          "Paprika",
        ],
      },
      {
        name: "Mozzarella",
        prices: [8.5, 15.5],
        id: 23,
        ingredients: [
          "Tomaten",
          "Käse",
          "Tomatenscheiben",
          "Mozzarella",
          "Basilikum",
        ],
      },
      {
        name: "Casa",
        prices: [8.5, 15.5],
        id: 24,
        ingredients: ["Tomaten", "Käse", "Schinken", "Broccoli", "Gorgonzola"],
      },
      {
        name: "Calzone",
        prices: [8.0, 15.0],
        id: 25,
        ingredients: [
          "(Geklappt)",
          "Tomaten (doppelt)",
          "Käse (doppelt)",
          "Salami",
          "Schinken",
          "Champignons",
        ],
      },
      {
        name: "Popey",
        prices: [8.0, 15.0],
        id: 26,
        ingredients: [
          "Tomaten",
          "Käse",
          "Schinken",
          "Spinat",
          "Zwiebeln",
          "Spiegelei",
        ],
      },
      {
        name: "Kikeriki",
        prices: [9.5, 17.5],
        id: 27,
        ingredients: [
          "Tomaten",
          "Käse",
          "Putenfleisch",
          "Champignons",
          "Zwiebeln",
          "Paprika",
        ],
      },
      {
        name: "Salmone",
        prices: [9.0, 17.0],
        id: 29,
        ingredients: ["Tomaten", "Käse", "Räucherlachs", "Shrimps", "Zwiebeln"],
      },
    ],
    salad: [
      {
        name: "Gemischter Salat",
        price: 5.5,
        id: 30,
        ingredients: ["Blattsalate", "Gurken", "Zwiebeln", "Tomaten"],
      },
      {
        name: "Tomatensalat",
        price: 6.5,
        id: 31,
        ingredients: ["Blattsalate", "Tomaten", "Zwiebeln"],
      },
      {
        name: "Tomatensalat Spezial",
        price: 8.5,
        id: 32,
        ingredients: [
          "Blattsalate",
          "Tomaten",
          "Zwiebeln",
          "Thunfisch",
          "Ei",
          "Kapern",
        ],
      },
      {
        name: "Bauernsalat",
        price: 7.5,
        id: 33,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Paprika",
        ],
      },
      {
        name: "Mozzarella Caprese",
        price: 8.0,
        id: 34,
        ingredients: [
          "Blattsalate",
          "Tomaten",
          "Mozzarella",
          "Basilikum",
          "Essig+Öl",
        ],
      },
      {
        name: "Italienischer Salat",
        price: 8.5,
        id: 35,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Käse",
          "Schinken",
          "Thunfisch",
          "Ei",
        ],
      },
      {
        name: "Capriciosa Salat",
        price: 8.5,
        id: 36,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Käse",
          "Schinken",
          "Shrimps",
          "Ei",
        ],
      },
      {
        name: "Schafskäsesalat",
        price: 8.5,
        id: 37,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Paprika",
          "Weichkäse",
          "Peperoni",
        ],
      },
      {
        name: "Tacchino Salat",
        price: 8.5,
        id: 38,
        ingredients: [
          "Blattsalate",
          "Gurken",
          "Zwiebeln",
          "Tomaten",
          "Mais",
          "Paprika",
          "Putenbruststreifen",
        ],
      },
    ],
    noodles: [
      {
        name: "Lasagne",
        price: 8.5,
        id: 400,
        ingredients: [
          "feine Schicht Nudeln, Fleisch- u. Bechamelsauce",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Lasagne Spezial",
        price: 9.5,
        id: 401,
        ingredients: [
          "feine Schicht Nudeln, Fleisch- u. Bechamelsauce",
          "Champignons",
          "Broccoli",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Combinazione",
        price: 8.5,
        id: 402,
        ingredients: [
          "Spaghettin",
          "Riggatoni",
          "Tortellini",
          "Fleisch- u. Sahnesauce",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Gnocchi al forno",
        price: 8.5,
        id: 403,
        ingredients: [
          "Ital. Kartoffelnudeln",
          "Fleisch- u. Sahnesauce",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Rigatoni al forno",
        price: 8.5,
        id: 404,
        ingredients: [
          "Röhrennudeln",
          "Tomaten- u. Sahnesauce",
          "Schinken",
          "Erbsen",
          "Ei",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Tortellini al forno",
        price: 9.0,
        id: 405,
        ingredients: [
          "Gefüllte Teigtaschen",
          "Fleisch- u. Sahnesauce",
          "Käse (überbacken)",
        ],
      },
      {
        name: "Canneloni",
        price: 8.5,
        id: 406,
        ingredients: [
          "Hohlnudeln",
          "Hackfleischfüllung",
          "Tomaten- u. Sahnesauce",
          "Käse (überbacken)",
        ],
      },
    ],
    spaghetti: [
      {
        name: "Spaghetti Napoli",
        price: 6.5,
        id: 40,
        ingredients: ["Tomatensauce"],
      },
      {
        name: "Spaghetti al pesto",
        price: 8,
        id: 42,
        ingredients: ["Basilikum", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Spaghetti Bolognese",
        price: 8,
        id: 42,
        ingredients: ["Fleischsauce"],
      },
      {
        name: "Spaghetti Carbonara",
        price: 8,
        id: 43,
        ingredients: ["Schinken", "Ei", "Sahnesauce"],
      },
      {
        name: "Spaghetti alla Paesana",
        price: 8,
        id: 44,
        ingredients: [
          "Schinken",
          "Erbsen",
          "Champignons",
          "Tomaten- u. Sahnesauce",
        ],
      },
      {
        name: "Spaghetti Grecia",
        price: 9,
        id: 45,
        ingredients: [
          "Weichkäse",
          "Paprika",
          "Broccoli",
          "Knoblauch",
          "Sahnesauce",
        ],
      },
    ],
    rigatoni: [
      {
        name: "Rigatoni Contandina",
        price: 8,
        id: 60,
        ingredients: ["Schinken", "Champignons", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Rigatoni al Pesto",
        price: 8,
        id: 61,
        ingredients: ["Basilikum", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Rigatoni Bolognese",
        price: 8,
        id: 62,
        ingredients: ["Fleischsauce"],
      },
      {
        name: "Rigatoni alla Panna",
        price: 8,
        id: 63,
        ingredients: ["Schinken", "Sahnesauce"],
      },
      {
        name: "Rigatoni agli spinaci",
        price: 8,
        id: 64,
        ingredients: ["Schinken", "Spinat", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Rigatoni paesana",
        price: 8,
        id: 65,
        ingredients: [
          "Schinken",
          "Erbsen",
          "Champignons",
          "Tomaten- u. Sahnesauce",
        ],
      },
    ],
    tagliatelle: [
      {
        name: "Tagliatelle Contadina",
        price: 8,
        id: 70,
        ingredients: ["Schinken", "Champignons", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Tagliatelle al pesto",
        price: 8,
        id: 71,
        ingredients: ["Basilikum", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Tagliatelle alla panna",
        price: 8,
        id: 72,
        ingredients: ["Schinken", "Sahnesauce"],
      },
      {
        name: "Tagliatelle Salmone",
        price: 9.5,
        id: 73,
        ingredients: ["Räucherlachs", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Tagliatelle Grecia",
        price: 9,
        id: 74,
        ingredients: [
          "Weichkäse",
          "Paprika",
          "Broccoli",
          "Knoblauch",
          "Sahnesauce",
        ],
      },
    ],
    tortellini: [
      {
        name: "Tortellini Contadina",
        price: 8.5,
        id: 80,
        ingredients: ["Schinken", "Champignons", "Knoblauch", "Sahnesauce"],
      },
      {
        name: "Tortellini Bolognese",
        price: 8,
        id: 81,
        ingredients: ["Fleischsauce"],
      },
      {
        name: "Tortellini alla panna",
        price: 8,
        id: 82,
        ingredients: ["Schinken", "Sahnesauce"],
      },
      {
        name: "Tortellini casa",
        price: 9,
        id: 83,
        ingredients: [
          "Broccoli",
          "Champignons",
          "Schinken",
          "Knoblauch",
          "Sahnesauce",
          "(pikant)",
        ],
      },
      {
        name: "Tortellini grecia",
        price: 9,
        id: 84,
        ingredients: [
          "Weichkäse",
          "Paprika",
          "Broccoli",
          "Knoblauch",
          "Sahnesauce",
        ],
      },
    ],
    diverse: [
      {
        name: "Pizzabrot",
        price: 4.5,
        id: 1,
        ingredients: ["Tomatensauce"],
      },
      {
        name: "Sandwichbrot",
        price: 4,
        id: 214,
        ingredients: [],
      },
      {
        name: "Sandwichbrot Spezial",
        price: 4.5,
        id: 215,
        ingredients: ["Käse (überbacken)"],
      },
      {
        name: "Calamari Romana",
        price: 10.5,
        id: 410,
        ingredients: ["frittierte Tintenfischringe", "Knoblauchsauce"],
      },
      {
        name: "Schnitzel Wiener Art",
        price: 10.5,
        id: 411,
        ingredients: ["paniertes Schweineschnitzel", "Zitrone"],
      },
      {
        name: "Parmesanschnitzel",
        price: 11.5,
        id: 412,
        ingredients: ["mit Fleisch-Sahnesauce", "mit Käse überbacken"],
      },
      {
        name: "Schnitzel Casa",
        price: 11.5,
        id: 413,
        ingredients: [
          "mit Broccoli und Pilzen",
          "Tomaten-Sahnesauce",
          "mit Käse überbacken",
        ],
      },
      {
        name: "Rahmschnitzel",
        price: 11.5,
        id: 414,
        ingredients: ["mit Champignons und Rahmsauce"],
      },
      {
        name: "Zigeunerschnitzel",
        price: 11.5,
        id: 415,
        ingredients: [
          "mit Zwiebeln",
          "Paprika",
          "Champignons und Tomaten-Sahnesauce",
          "mit Käse überbacken",
        ],
      },
      {
        name: "Bologneseschnitzel",
        price: 11.5,
        id: 416,
        ingredients: [
          "mit Schinken",
          "Tomaten-Sahnesauce",
          "mit Käse überbacken",
        ],
      },
      {
        name: "Cordon Bleu",
        price: 11.5,
        id: 417,
        ingredients: ["vom Schwein", "grfüllt mit Schinken und Käse"],
      },
      {
        name: "Jägerschnitzel",
        price: 11.5,
        id: 418,
        ingredients: ["mit brauner Sauce", "Pilzen"],
      },
      {
        name: "Portion Pommes",
        price: 4,
        id: 419,
        ingredients: ["mit Ketchup"],
      },
    ],
    sandwiches: [
      {
        name: "Jumbo Hähnchen",
        price: 8.5,
        id: 50,
        ingredients: [
          "mit frittierter Hähnchenbrust",
          "Salat",
          "Tomaten",
          "Gurken",
          "Zwiebeln",
          "Dressing",
        ],
      },
      {
        name: "Jumbo Schnitzel",
        price: 8.5,
        id: 51,
        ingredients: [
          "mit frittiertem Schnitzel",
          "Salat",
          "Tomaten",
          "Gurken",
          "Zwiebeln",
          "Dressing",
        ],
      },
      {
        name: "Jumbo Tonno",
        price: 8.5,
        id: 52,
        ingredients: [
          "mit Thunfisch",
          "Ei",
          "Salat",
          "Tomaten",
          "Gurken",
          "Zwiebeln",
          "Dressing",
        ],
      },
    ],
  },
  drinks: [
    {
      name: "Coca Cola / Fanta (1,0 l)",
      price: 3,
      id: 90,
    },
    {
      name: "Mineralwasser (0,7 l)",
      price: 2,
      id: 91,
    },
    {
      name: "Flasche Bier (0,5 l)",
      price: 3.0,
      id: 92,
    },
    {
      name: "Flasche Wein (0,7 l)",
      price: 8.5,
      id: 96,
    },
  ],
};
