import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Card, CardHeader, CardBody, 
  Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Table } from 'reactstrap';
import Scrollspy from 'react-scrollspy'
import Privacy from './Privacy'



import menu from './data/menu';

import './App.scss';

interface AppState {
  navIsOpen: boolean;
  privacyModalOpen: boolean;
  sections: {id: string, name:string}[]
}

// TODO: remove types 'any' where typeable

class App extends Component<{}, AppState> {

  myRefs: any = {}

  constructor(props: any) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      navIsOpen: false,
      privacyModalOpen: false,
      sections: [{
        id: 'pizza',
        name: "Pizza",
      }, {
        id: 'pasta',
        name: "Pasta",
      }, {
        id: 'salat',
        name: "Salat",
      }, {
        id: 'fleisch',
        name: "Verschiedenes",
      }, {
        id: 'drinks',
        name: "Getränke",
      }, {
        id: 'footer',
        name: "Kontakt",
      }]
    };

    this.state.sections.forEach(s=> this.myRefs[s.id]=React.createRef())

  }

  toggleNavbar() {
    this.setState({
      navIsOpen: !this.state.navIsOpen
    });
  }

  renderDishes(dishes: any) {

    const priceCells = (dish: any) => {
      const prices = dish.price ? [dish.price] : dish.prices;
      return prices.map((price: number, i: number) => 
        <td key={`${dish.id}_${i}`}>{`${price.toFixed(2)}`.replace('.', ',')} &euro;</td>
      );
    }

    let header = ["#", "Gericht", "Preis"];
    // todo check bounds
    if (dishes[0].prices) { 
      header = ["#", "Gericht", "Normal (30cm)", "Family (40cm)"];
    }

    return (
      <Table hover responsive>
        <thead>
          <tr>
            { header.map((h,i) => <th key={`${dishes.name}_h_${i}`}>{h}</th>) }
          </tr>
        </thead>
        <tbody>
          {
            dishes.map((d: any, i: number) => 
              <tr key={`${d.id}_${i}`}>
                <th scope="row">{d.id}</th>
                <td className="name">
                  <span className="label">{d.name}</span>
                  { d.ingredients && 
                    <span className="ingredients">{d.ingredients.join(', ')}</span>
                  }
                </td>
                { priceCells(d) }
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  }

  handleNavItemClick = (e: any) => {
    const ref = this.myRefs[e.target.hash.replace('#', '')];
    this.scrollToMyRef(ref);
    this.setState({
      navIsOpen: false
    });
    e.preventDefault();
  }
  
  scrollToMyRef = (ref: any) => {
    const offset = 200; // TODO: find out why this offset is required
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = ref.current.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  }
  
  showPrivacy = (e: any) => {
    this.setState({
      privacyModalOpen: !this.state.privacyModalOpen
    });
    e.preventDefault();
  }

  render() {

    return (
      <React.Fragment>
        
        <Navbar color="light" light expand="md" sticky="top">
          <NavbarBrand href="/">Pizza 1415</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} aria-label="Navigation umschalten" />
          <Collapse isOpen={this.state.navIsOpen} navbar>
            <Scrollspy items={ this.state.sections.map(s => s.id) } 
              className="ml-auto navbar-nav"
              currentClassName="active" 
              scrolledPastClassName="passive">
              {
                this.state.sections.map(s => 
                  <NavItem key={s.id}>
                    <NavLink href={`#${s.id}`}
                      onClick={this.handleNavItemClick}>
                      {s.name}
                    </NavLink>
                  </NavItem>
                )
              }
            </Scrollspy>
          </Collapse>
        </Navbar>

        <Container fluid className="splash align-middle text-center">
          <Row>
            <Col>
              <h1 className="center green varsize">Pizza-Heim-Service</h1>
              <h1 className="center red varsize">Telefon: 06108&#8239;/&#8239;1415</h1>
              <h4 className="center varsize">Offenbacher Str. 14 - 63165&nbsp;Mühlheim&nbsp;am&nbsp;Main</h4>
            </Col>
          </Row>
        </Container>

        <Container id="dishes">
          <Row>
            <Col xs="12" lg="3" >
              <Card>
                <CardHeader>Öffnungszeiten</CardHeader>
                <CardBody>
                  <p>
                    <strong>Dienstag bis Freitag: </strong><br></br>
                    11:00&nbsp;&#8209;&nbsp;13:30 <br></br>
                    17:00&nbsp;&#8209;&nbsp;21:00 
                  </p>
                  <p>
                    <strong>Samstag: </strong><br></br>
                    17:00&nbsp;&#8209;&nbsp;21:00
                  </p>
                  <p>
                    <strong>Sonntag: </strong><br></br>
                    11:00&nbsp;&#8209;&nbsp;13:30 <br></br>
                    17:00&nbsp;&#8209;&nbsp;21:00
                  </p>
                  <p>
                    <strong>Montag:</strong> Ruhetag
                  </p>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>Lieferservice</CardHeader>
                <CardBody>
                  <p>
                    <strong>Lieferung ab 15&euro; frei Haus.</strong><br></br>
                    Unter 15&euro;: 1,50&euro; Zuschlag
                  </p>
                  <p>
                    <strong>Wie liefern nach:</strong><br></br>
                    Mühlheim, Dietesheim, Lämmerspiel, OF&#8209;Waldheim, OF&#8209;Rumpenheim, OF&#8209;Bürgel, OF-Mühlheimer&nbsp;Straße, Rote&nbsp;Warte
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" lg="9">
              <div ref={this.myRefs['pizza']}></div>
              <h2 className="first-headline" id="pizza">Pizza</h2>
              {this.renderDishes(menu.dishes.pizza)}
            </Col>
          </Row>

          <div ref={this.myRefs['pasta']}></div>
          <Row id="pasta">
            <Col xs="12" lg="3"></Col>
            <Col xs="12" lg="9">
              <Row>
                <Col xs="12" lg="6">
                  <h2>Nudelgerichte</h2>
                  {this.renderDishes(menu.dishes.noodles)}
                </Col>
                <Col xs="12" lg="6">
                  <h2>Tagliatelle</h2>
                  {this.renderDishes(menu.dishes.tagliatelle)}
                </Col>
                <Col xs="12" lg="6">
                  <h2>Spaghetti</h2>
                  {this.renderDishes(menu.dishes.spaghetti)}
                </Col>
                <Col xs="12" lg="6">
                  <h2>Tortellini</h2>
                  {this.renderDishes(menu.dishes.tortellini)}
                </Col>
                <Col xs="12" lg="6">
                  <h2>Rigatoni</h2>
                  {this.renderDishes(menu.dishes.rigatoni)}
                </Col>
              </Row>
            </Col>
          </Row>

          <div ref={this.myRefs['salat']}></div>
          <Row id="salat">
            <Col xs="12" lg="3"></Col>
            <Col xs="12" lg="9">
              <h2>Salat</h2>
              {this.renderDishes(menu.dishes.salad)}
            </Col>
          </Row>

          <div ref={this.myRefs['fleisch']}></div>
          <Row id="fleisch">
            <Col xs="12" lg="3"></Col>
            <Col xs="12" lg="9">
              <h2>Verschiedenes</h2>
              {this.renderDishes(menu.dishes.diverse)}
            </Col>
            <Col xs="12" lg="3"></Col>
            <Col xs="12" lg="9">
              <h2>Jumbo-Sandwiches</h2>
              {this.renderDishes(menu.dishes.sandwiches)}
            </Col>
          </Row>
          
          <div ref={this.myRefs['drinks']}></div>
          <Row id="drinks">
            <Col xs="12" lg="3"></Col>
            <Col xs="12" lg="9">
              <h2>Getränke</h2>
              {this.renderDishes(menu.drinks)}
            </Col>
          </Row>

        </Container>

        <div ref={this.myRefs['footer']}></div>
        <footer className="footer" id="footer">
          <Container>
            <Row id="contact">
              <Col xs="12" lg="4">
                <h6>Kontakt &amp; Impressum</h6>
                <p>
                  Pizza-Heim-Service<br></br>
                  Stefano Perozzo<br></br>
                  Offenbacher Str. 14<br></br>
                  63165 Mühlheim am Main<br></br>
                  Tel: 06108 / 1415<br></br>
                  Mail: info@pizza1415.de<br></br>
                </p>
              </Col>
              <Col xs="12" lg="4">
                <h6>Öffnungszeiten</h6>
                <p>
                    Dienstag bis Freitag:<br></br>
                    11:00&nbsp;&#8209;&nbsp;13:30 &amp; 17:00&nbsp;&#8209;&nbsp;21:00 
                  </p>
                  <p>
                    Samstag:<br></br>
                    17:00&nbsp;&#8209;&nbsp;21:00
                  </p>
                  <p>
                    Sonntag:<br></br>
                    11:00&nbsp;&#8209;&nbsp;13:30 &amp; 17:00&nbsp;&#8209;&nbsp;21:00
                  </p>
              </Col>
              <Col xs="12" lg="4">
                <h6>Rechtliches</h6>
                <p>
                  <a href="" onClick={this.showPrivacy}>Datenschutz</a>
                  <Modal isOpen={this.state.privacyModalOpen} toggle={this.showPrivacy} size="lg">
                    <ModalHeader toggle={this.showPrivacy}>Datenschutz</ModalHeader>
                    <ModalBody className="privacy">
                      <Privacy className="privacy__content"/>
                    </ModalBody>
                  </Modal>
                </p>
              </Col>
            </Row>
            <div style={{marginTop: '30px', float: 'right'}}>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
          </Container>
        </footer>

      </React.Fragment>
    );
  }
}

export default App;
